import gql from 'graphql-tag'


export const LIST_PROVINCE = (templateType) => gql`query LIST_PROVINCE ($countryAreaType: String!, $q: FilterInput) {
  provinces: listCountryArea${templateType}Province (countryAreaType: $countryAreaType, q: $q) {
    id name
  }
}`

export const CREATE_PROVINCE = (templateType) => gql`mutation CREATE_PROVINCE ($countryAreaType: String!, $countryAreaId: Int!, $countryProvinceId: Int!) {
  createProvince: createCountryArea${templateType}Province (countryAreaType: $countryAreaType, countryAreaId: $countryAreaId, countryProvinceId: $countryProvinceId) {
    countryAreaId countryProvinceId
  }
}`

export const DESTROY_PROVINCE = (templateType) => gql`mutation DESTROY_PROVINCE ($countryAreaType: String!, $countryAreaId: Int!, $countryProvinceId: Int!) {
  destroyProvince: destroyCountryArea${templateType}Province (countryAreaType: $countryAreaType, countryAreaId: $countryAreaId, countryProvinceId: $countryProvinceId) {
    countryAreaId countryProvinceId
  }
}`
