import gql from 'graphql-tag'


export const LIST_SUBDISTRICT = (templateType) => gql`query LIST_SUBDISTRICT ($countryAreaType: String!, $q: FilterInput) {
  subdistricts: listCountryArea${templateType}Subdistrict (countryAreaType: $countryAreaType, q: $q) {
    id name
  }
}`

export const CREATE_SUBDISTRICT = (templateType) => gql`mutation CREATE_SUBDISTRICT ($countryAreaType: String!, $countryAreaId: Int!, $countrySubdistrictId: Int!) {
  createSubdistrict: createCountryArea${templateType}Subdistrict (countryAreaType: $countryAreaType, countryAreaId: $countryAreaId, countrySubdistrictId: $countrySubdistrictId) {
    countryAreaId countrySubdistrictId
  }
}`

export const DESTROY_SUBDISTRICT = (templateType) => gql`mutation DESTROY_SUBDISTRICT ($countryAreaType: String!, $countryAreaId: Int!, $countrySubdistrictId: Int!) {
  destroySubdistrict: destroyCountryArea${templateType}Subdistrict (countryAreaType: $countryAreaType, countryAreaId: $countryAreaId, countrySubdistrictId: $countrySubdistrictId) {
    countryAreaId countrySubdistrictId
  }
}`
