import gql from 'graphql-tag'


const listResponse = `
  id name
  contacts {id type code name}
`

export const LIST_AREA = (templateType) => gql`query LIST_AREA ($countryAreaType: String!, $q: FilterInput) {
  areas: listCountryArea${templateType} (countryAreaType: $countryAreaType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id name
  provinces {id name}
  districts {id name}
  subdistricts {id name}
  contacts {id type code name}
`

export const DETAIL_AREA = (templateType) => gql`query DETAIL_AREA ($countryAreaType: String!, $countryAreaId: Int!) {
  area: detailCountryArea${templateType} (countryAreaType: $countryAreaType, countryAreaId: $countryAreaId) {${detailResponse}}
}`

export const CREATE_AREA = (templateType) => gql`mutation CREATE_AREA ($countryAreaType: String!, $input: CountryArea${templateType}Input!) {
  createCountryArea: createCountryArea${templateType} (countryAreaType: $countryAreaType, input: $input) {${detailResponse}}
}`

export const UPDATE_AREA = (templateType) => gql`mutation UPDATE_AREA ($countryAreaType: String!, $countryAreaId: Int!, $input: CountryArea${templateType}Input!) {
  updateCountryArea: updateCountryArea${templateType} (countryAreaType: $countryAreaType, countryAreaId: $countryAreaId, input: $input) {${detailResponse}}
}`

export const DESTROY_AREA = (templateType) => gql`mutation DESTROY_AREA ($countryAreaType: String!, $countryAreaId: Int!) {
  destroyCountryArea: destroyCountryArea${templateType} (countryAreaType: $countryAreaType, countryAreaId: $countryAreaId) {id}
}`
