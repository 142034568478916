<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          {{title}}
        </h3>
      </div>
    </div>

    <hr class="my-3">

    <sgv-table
      :rKey="rKey"
      :items="areas"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options">
      <template slot-scope="{item, hidden}">
        <tr>
          <td
            v-if="hidden.name"
            @click="toDetail(item.id)"
            class="pointer">
            {{ item.name }}
          </td>
          <td
            v-if="hidden.contacts">
            <div v-for="contact in item.contacts" :key="contact.id">
              {{ contact.code }} ({{ contact.name }})
            </div>
          </td>
        </tr>
      </template>

      <b-button
        slot="action"
        variant="link"
        v-if="$auth.hasRole(`countryArea:${countryAreaType}:add`)"
        class="text-success"
        @click="toDetail(0)">
        เพิ่ม
      </b-button>

    </sgv-table>
  </div>
</template>

<script>
import { LIST_AREA } from './graph'
import retainMixin from '@/mixins/retain-mixin'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    countryAreaType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      detailView: `CountryArea${this.$form.capitalize(this.countryAreaType)}Detail`,
      rKey: `CountryArea${this.$form.capitalize(this.countryAreaType)}List`,
      rFields: ['filter', 'options'],
      headers: [
        {text: 'ชื่อ', value: 'name', sort: true, filter: true},
        {text: 'ผู้ติดต่อ', value: 'contacts'},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['name', 'contacts'],
        column: null,
        search: null,
        toolbar: null,
      },
      areas: [],
    }
  },
  apollo: {
    areas: {
      query () {
        return LIST_AREA(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          countryAreaType: this.countryAreaType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    getFilter (v) {
      return {
        ...v,
        params: v.params
      }
    },
    toDetail (id) {
      this.$router.push({
        name: this.detailView,
        params: {countryAreaId: id}
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
