import gql from 'graphql-tag'


export const LIST_DISTRICT = (templateType) => gql`query LIST_DISTRICT ($countryAreaType: String!, $q: FilterInput) {
  districts: listCountryArea${templateType}District (countryAreaType: $countryAreaType, q: $q) {
    id name
  }
}`

export const CREATE_DISTRICT = (templateType) => gql`mutation CREATE_DISTRICT ($countryAreaType: String!, $countryAreaId: Int!, $countryDistrictId: Int!) {
  createDistrict: createCountryArea${templateType}District (countryAreaType: $countryAreaType, countryAreaId: $countryAreaId, countryDistrictId: $countryDistrictId) {
    countryAreaId countryDistrictId
  }
}`

export const DESTROY_DISTRICT = (templateType) => gql`mutation DESTROY_DISTRICT ($countryAreaType: String!, $countryAreaId: Int!, $countryDistrictId: Int!) {
  destroyDistrict: destroyCountryArea${templateType}District (countryAreaType: $countryAreaType, countryAreaId: $countryAreaId, countryDistrictId: $countryDistrictId) {
    countryAreaId countryDistrictId
  }
}`
