import gql from 'graphql-tag'


export const LIST_CONTACT = (templateType) => gql`query LIST_CONTACT ($countryAreaType: String!, $q: FilterInput) {
  contacts: listCountryArea${templateType}Contact (countryAreaType: $countryAreaType, q: $q) {
    id code name
  }
}`

export const CREATE_CONTACT = (templateType) => gql`mutation CREATE_CONTACT ($countryAreaType: String!, $countryAreaId: Int!, $contactId: Int!) {
  createContact: createCountryArea${templateType}Contact (countryAreaType: $countryAreaType, countryAreaId: $countryAreaId, contactId: $contactId) {
    countryAreaId contactId
  }
}`

export const DESTROY_CONTACT = (templateType) => gql`mutation DESTROY_CONTACT ($countryAreaType: String!, $countryAreaId: Int!, $contactId: Int!) {
  destroyContact: destroyCountryArea${templateType}Contact (countryAreaType: $countryAreaType, countryAreaId: $countryAreaId, contactId: $contactId) {
    countryAreaId contactId
  }
}`
